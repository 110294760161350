<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <transition name="fade">
      <columns-select
        :visible="showColumnsSelect"
        :columns="columns"
        :name="'admins_columns'"
        @hide="showColumnsSelect = false"
        @selectconfirmed="updateColumns(currentColumns)"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.admins.delete_all')"
        :visible="showDeleteAll"
        @confirmed="groupDelete()"
        @hide="showDeleteAll = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.admins.delete')"
        :visible="showDelete"
        @confirmed="deleteData"
        @hide="showDelete = false"
      />
    </transition>
    <transition name="expandy">
      <tables-context :display="showContext" ref="menu">
        <ul
          v-if="selectedData"
          :class="{
            disabled: !this.$store.getters.settings.license.active,
          }"
        >
          <li
            v-if="hasPrivilege('admins_edit_admins')"
            @click="editData(selectedData)"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-blue-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
            {{ $t("actions.edit") }}
          </li>
          <li
            v-if="hasPrivilege('admins_delete_admins')"
            @click="showDelete = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {{ $t("actions.delete") }}
          </li>
        </ul>
      </tables-context>
    </transition>

    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-end">
        <div class="flex">
          <button
            class="rounded-full bg-ssr-side_bar-settings text-xs lg:text-base text-white py-1 lg:py-2 px-4 font-bold mr-2"
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>
          <button
            v-if="hasPrivilege('admins_edit_admins')"
            @click.prevent="
              this.$store.commit('changeKey');
              this.$router.push('/admins/create');
            "
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white lg:p-2 px-2"
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            {{ $t("admins.add_admins") }}
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center  overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th></th>
            <th>
              <table-check-box
                class="w-5 h-5 my-3 mx-4 cursor-pointer"
                :value="allSelected"
                @click="checkAll"
              />
            </th>
            <th></th>
            <th v-if="columns.first_name.enabled">
              <div class="inline-block">{{ $t("admins.first_name") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.first_name.sort }"
                @click="sort('first_name')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('admins_view_search_admins')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.first_name"
              />
            </th>
            <th v-if="columns.last_name.enabled">
              <div class="inline-block">{{ $t("admins.last_name") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.last_name.sort }"
                @click="sort('last_name')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('admins_view_search_admins')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.last_name"
              />
            </th>
            <th v-if="columns.email.enabled">
              <div class="inline-block">{{ $t("admins.email") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.email.sort }"
                @click="sort('email')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('admins_view_search_admins')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.email"
              />
            </th>
            <th v-if="columns.password.enabled">
              <div>{{ $t("admins.password") }}</div>
            </th>
            <th v-if="columns.phone.enabled">
              <div class="inline-block">{{ $t("admins.phone") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.phone.sort }"
                @click="sort('phone')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </th>
            <th v-if="columns.prefix.enabled">
              <div class="inline-block">{{ $t("admins.prefix") }}</div>
            </th>
            <th v-if="columns.privleges.enabled">
              <div>{{ $t("admins.privileges") }}</div>
            </th>
            <th v-if="columns.allowed_nases.enabled">
              <div>{{ $t("admins.allowed_nases") }}</div>
            </th>
            <th v-if="columns.allowed_profiles.enabled">
              <div>{{ $t("nases.allowed_profiles") }}</div>
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(admin, index) in dataList"
              v-bind:key="admin.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
                'bg-blue-300': admin.selected,
              }"
              @contextmenu.prevent="openContextMenu($event, admin)"
            >
              <td class="font-bold px-2 text-lg">
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="admin.selected"
                  @click="selectData(admin)"
                />
              </td>
              <td
                :class="{
                  disabled: !this.$store.getters.settings.license.active,
                }"
              >
                <img
                  @click.prevent="editData(admin)"
                  v-if="admin.profile_image"
                  class="rounded-full h-12 w-12 mx-5 p-1 cursor-pointer"
                  :src="server_url + admin.profile_image"
                  alt="Person Image"
                />
                <img
                  @click.prevent="editData(admin)"
                  v-if="!admin.profile_image"
                  class="rounded-full h-12 w-12 mx-5 p-1 cursor-pointer"
                  src="@/assets/person.png"
                  alt="Person Image"
                />
              </td>
              <td v-if="columns.first_name.enabled">
                <a
                  class="cursor-pointer underline text-blue-800"
                  @click.prevent="editData(admin)"
                  :class="{
                    disabled: !this.$store.getters.settings.license.active,
                  }"
                >
                  {{ admin.first_name }}
                </a>
              </td>
              <td v-if="columns.last_name.enabled">
                {{ admin.last_name }}
              </td>
              <td v-if="columns.email.enabled">
                <a
                  class="cursor-pointer underline text-blue-800"
                  @click.prevent="editData(admin)"
                  :class="{
                    disabled: !this.$store.getters.settings.license.active,
                  }"
                >
                  {{ admin.email }}
                </a>
              </td>
              <td v-if="columns.password.enabled">
                {{ admin.password }}
              </td>
              <td v-if="columns.phone.enabled">
                {{ admin.phone }}
              </td>
              <td v-if="columns.prefix.enabled">
                {{ admin.prefix }}
              </td>
              <td v-if="columns.privleges.enabled">
                {{ admin.privleges }}
              </td>
              <td v-if="columns.allowed_nases.enabled">
                <div
                  class=" max-h-24 overflow-auto"
                  v-if="hasPrivilege('admins_assign_nases')"
                >
                  <div v-for="nas in admin.allowed_nases" v-bind:key="nas.id">
                    <span
                      class="text-white px-2 text-sm bg-green-400 rounded-lg"
                      >{{ nas.shortname }}</span
                    >
                  </div>
                </div>
              </td>
              <td v-if="columns.allowed_profiles.enabled">
                <div
                  class=" max-h-24 overflow-auto"
                  v-if="hasPrivilege('admins_assign_profiles')"
                >
                  <div
                    v-for="profile in admin.allowed_profiles"
                    v-bind:key="profile.id"
                  >
                    <span
                      class="text-white px-2 text-sm bg-green-400 rounded-lg"
                      >{{ profile.name }}</span
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class=" inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="hasPrivilege('admins_edit_admins')"
          @click="editData(selection_array[0])"
          class="mx-3 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-blue-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.edit") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('admins_delete_admins')"
          @click="
            selectedData = selection_array[0];
            showDelete = true;
          "
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length > 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="hasPrivilege('admins_delete_admins')"
          @click="showDeleteAll = true"
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PillDefault from "../components/PillDefault.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import TablesContext from "../components/contexts/TablesContext.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import ProfileDetails from "../components/modals/ProfileDetails.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import PieChart from "../components/PieChart.vue";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import CardDetails from "../components/modals/CardDetails.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
export default {
  components: {
    PillDefault,
    DeleteConfirm,
    PaginationRow,
    TablesContext,
    ColumnsSelect,
    TableCheckBox,
    PieChart,
    WarnningConfirm,
    CardDetails,
  },
  name: "Admins",
  inject: {
    server_url: {
      from: "server_url",
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      dataList: null,
      meta: { last_page: 0 },
      selectedData: null,
      showDetails: false,
      showDelete: false,
      showDisconnect: false,
      showDisable: false,
      showDeleteAll: false,
      showContext: false,
      loading: true,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone: "",
        prefix: "",
        privleges: "",
      },
      checkedBox: true,
      allSelected: false,
      showColumnsSelect: false,
      columns: {
        first_name: {
          name: this.$t("admins.first_name"),
          enabled: true,
          sort: false,
        },
        last_name: {
          name: this.$t("admins.last_name"),
          enabled: false,
          sort: false,
        },
        email: { name: this.$t("admins.email"), enabled: true, sort: false },
        password: {
          name: this.$t("admins.password"),
          enabled: false,
          sort: false,
        },
        phone: { name: this.$t("admins.phone"), enabled: false, sort: false },
        prefix: { name: this.$t("admins.prefix"), enabled: false, sort: false },
        privleges: {
          name: this.$t("admins.privileges"),
          enabled: false,
          sort: false,
        },
        allowed_nases: {
          name: this.$t("admins.allowed_nases"),
          enabled: false,
          sort: false,
        },
        allowed_profiles: {
          name: this.$t("nases.allowed_profiles"),
          enabled: true,
          sort: false,
        },
      },
      autoUpdate: false,
      selection_array: [],
      debounce: null,
    };
  },
  async created() {
    this.prepareFilters();
    this.prepareColumns();
    await this.fetchData();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async fetchData() {
      if (this.filters.rows > 0 || this.filters.page > 0) {
        this.fetchDataPortion(this.filters.rows, this.filters.page);
        this.selection_array = [];
        return;
      }
      const response = await axios.get(`admins`);
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    prepareFilters() {
      var filters = localStorage.getItem("admins_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {
      var cols = localStorage.getItem("admins_columns");
      if (cols != null) {
        cols = JSON.parse(cols);
        this.columns = {
          first_name: {
            name: this.$t("admins.first_name"),
            enabled: cols[0].enabled,
            sort: false,
          },
          last_name: {
            name: this.$t("admins.last_name"),
            enabled: cols[1].enabled,
            sort: false,
          },
          email: {
            name: this.$t("admins.email"),
            enabled: cols[2].enabled,
            sort: false,
          },
          password: {
            name: this.$t("admins.password"),
            enabled: cols[3].enabled,
            sort: false,
          },
          phone: { name: this.$t("admins.phone"), enabled: cols[4].enabled },
          prefix: {
            name: this.$t("admins.prefix"),
            enabled: cols[5] != null ? cols[5].enabled : false,
          },
          privleges: {
            name: this.$t("admins.privileges"),
            enabled: cols[6] != null ? cols[6].enabled : false,
            sort: false,
          },
          allowed_nases: {
            name: this.$t("admins.allowed_nases"),
            enabled: cols[7] != null ? cols[7].enabled : false,
            sort: false,
          },
          allowed_profiles: {
            name: this.$t("admins.allowed_profiles"),
            enabled: cols[8] != null ? cols[8].enabled : true,
            sort: false,
          },
        };
      }
    },
    async applyFilters() {
      this.loading = true;
      var requestString = `admins?filter=1`;
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.first_name != "")
        requestString += `&first_name=${this.filters.first_name}`;
      if (this.filters.last_name != "")
        requestString += `&last_name=${this.filters.last_name}`;
      if (this.filters.email != "")
        requestString += `&email=${this.filters.email}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.filters.save)
          localStorage.setItem("admins_filters", JSON.stringify(this.filters));
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    editData(selectedData) {
      this.$router.push(`/admins/${selectedData.id}/edit`);
    },
    async deleteData() {
      const response = await axios.delete(`admins/${this.selectedData.id}`);
      if (response.status == 200) {
        this.fetchData();
        this.showDelete = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    async groupDelete() {
      const response = await axios.post(`admins/group`, {
        admins: this.selection_array,
      });
      if (response.status == 200) {
        this.fetchData();
        this.showDeleteAll = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    openContextMenu(e, admin) {
      this.selectedData = admin;
      this.$refs.menu.open(e);
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    sort(param) {
      this.sort_col = param;
      if (!this.columns[param].sort) {
        this.sort_dir = "ASC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = true;
      } else {
        this.sort_dir = "DESC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = false;
      }
      this.applyFilters();
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem("admins_filters", JSON.stringify(this.filters));
    },
  },
};
</script>
