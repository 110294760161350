<template>
  <div class="main-content flex-1 relative">
    <loading-overlay :visible="loading" />
    <transition name="fade">
      <error-message
        :visible="isError"
        :message="error_message"
        @hide="isError = false"
      />
    </transition>
    <div v-if="settings.license.active" class="flex justify-center w-full">
      <div
        class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-10 md:px-20 justify-center items-center"
      >
        <div class="flex flex-col lg:w-1/2 justify-center items-center">
          <div class="w-full items-start grid grid-cols-2 gap-6 mt-6">
            <div>
              <label class="font-bold">{{ $t("accounts.first_name") }}</label>
              <input
                v-model="account.first_name"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_personal_accounts') && account.id
                "
              />
            </div>
            <div>
              <label class="font-bold">{{ $t("accounts.last_name") }}</label>
              <input
                v-model="account.last_name"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_personal_accounts') && account.id
                "
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{ $t("accounts.national_id") }}</label>
              <input
                v-model="account.national_id"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_personal_accounts') && account.id
                "
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{ $t("accounts.phone") }}</label>
              <input
                v-model="account.phone"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_personal_accounts') && account.id
                "
              />
            </div>

            <div class="col-span-2">
              <label class="font-bold">{{ $t("accounts.phone_line") }}</label>
              <input
                v-model="account.phone_line"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_personal_accounts') && account.id
                "
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{ $t("accounts.email") }}</label>
              <input
                v-model="account.email"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_personal_accounts') && account.id
                "
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{
                this.$store.getters.settings.center == 1
                  ? "رقم البوابة"
                  : $t("accounts.address")
              }}</label>
              <input
                v-model="account.address"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_personal_accounts') && account.id
                "
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{ $t("accounts.notes") }}</label>
              <input
                v-model="account.notes"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_personal_accounts') && account.id
                "
              />
            </div>
            <div>
              <label class="font-bold">{{ $t("accounts.username") }}</label>
              <input
                v-model="account.username"
                @input="validate"
                oninput="this.value=this.value.replace(/[^a-zA-Z0-9_.\-@\s]/g,'');"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :class="{
                  'shadow-error': errors.username,
                  'opacity-50': account.id && !settings.center,
                }"
                :disabled="
                  (account.id && !settings.center) ||
                    (!hasPrivilege('accounts_edit_username_accounts') &&
                      account.id)
                "
              />
              <div
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': errors.username }"
              >
                *{{ errors.username }}
              </div>
            </div>
            <div>
              <label class="font-bold">{{ $t("accounts.password") }}</label>
              <input
                v-model="account.password"
                @input="validate"
                oninput="this.value=this.value.replace(/[^a-zA-Z0-9_.-\s]/g,'');"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :class="{
                  'shadow-error': errors.password,
                }"
                :disabled="
                  !hasPrivilege('accounts_edit_password_accounts') && account.id
                "
              />
              <div
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': errors.password }"
              >
                *{{ errors.password }}
              </div>
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{ $t("accounts.profile") }}</label>
              <select
                v-model="account.profile_id"
                @change="
                  fix_date();
                  update_price();
                  fetchSerial();
                  validate();
                "
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :class="{ 'shadow-error': errors.profile_id }"
                :disabled="
                  (!hasPrivilege('accounts_edit_profile_accounts') &&
                    account.id) ||
                    (this.user.data.type == 'reseller' && account.id)
                "
              >
                <option
                  v-for="profile in profiles"
                  v-bind:key="profile.id"
                  :value="profile.id"
                >
                  {{ profile.name }}
                </option>
              </select>
              <div
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': errors.profile_id }"
              >
                *{{ errors.profile_id }}
              </div>
            </div>
            <div class="col-span-2" v-if="this.user.data.type != 'reseller'">
              <label class="font-bold">{{ $t("accounts.next_profile") }}</label>
              <select
                v-model="account.next_profile_id"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="
                  !hasPrivilege('accounts_edit_profile_accounts') && account.id
                "
              >
                <option value="">N/A</option>
                <option
                  v-for="profile in profiles"
                  v-bind:key="profile.id"
                  :value="profile.id"
                >
                  {{ profile.name }}
                </option>
              </select>
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{
                $t("accounts.serial_number")
              }}</label>
              <input
                v-model="account.serial_number"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 opacity-50 focus:outline-none"
                :class="{ 'shadow-error': errors.serial_number }"
                disabled
              />
              <div
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': errors.serial_number }"
              >
                *{{ errors.serial_number }}
              </div>
            </div>
            <!-- <div v-if="!this.account.id">
              <toggle-button
                class="shadow-lg"
                :value="has_fixed_expiration"
                :name="''"
                :disabled_name="$t('accounts.has_fixed_expiration_false')"
                :enabled_name="$t('accounts.has_fixed_expiration')"
                @toggle="has_fixed_expiration = !has_fixed_expiration"
              />
            </div> -->
            <div
              class="col-span-2"
              v-if="
                this.user.data.type != 'reseller' &&
                  hasPrivilege('accounts_edit_expiration_accounts')
              "
            >
              <label class="font-bold">{{
                $t("accounts.has_fixed_expiration")
              }}</label>
              <VueDatepicker
                class="w-full"
                v-model="account.fixed_expiration"
                :format="date_format"
                :startTime="startTime"
                :locale="$i18n.locale"
                autoApply
                :day-names="['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI']"
                :teleport="true"
              ></VueDatepicker>
            </div>
            <div class="col-span-2" v-if="this.user.data.type != 'reseller'">
              <label class="font-bold">{{ $t("accounts.reseller") }}</label>
              <select
                v-model="account.reseller_id"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="!hasPrivilege('accounts_reseller_accounts')"
              >
                <option class="font-light" :value="null">N/A</option>
                <option
                  v-for="reseller in resellers"
                  v-bind:key="reseller.id"
                  :value="reseller.id"
                >
                  {{ reseller.email }}
                </option>
              </select>
            </div>
            <!-- <div v-if="this.user.data.type != 'reseller'">
              <toggle-button
                class="shadow-lg"
                :value="has_expire_profile"
                :name="''"
                :disabled_name="$t('accounts.has_expire_profile_false')"
                :enabled_name="$t('accounts.has_expire_profile')"
                @toggle="
                  has_expire_profile = !has_expire_profile;
                  validate();
                  account.expire_profile = null;
                "
              />
            </div>
            <div v-if="this.user.data.type != 'reseller'">
              <select
                v-if="has_expire_profile"
                v-model="account.expire_profile"
                @change="validate"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              >
                <option value="128">128 Kb/s</option>
                <option value="256">256 Kb/s</option>
                <option value="512">512 Kb/s</option>
                <option value="1024">1 Mb/s</option>
              </select>
            </div> -->
            <div v-if="this.user.data.type != 'reseller'">
              <toggle-button
                class="shadow-lg"
                :value="has_static_ip"
                :enabled_name="$t('accounts.has_ip')"
                :disabled_name="$t('accounts.has_ip_false')"
                @toggle="
                  has_static_ip = !has_static_ip;
                  validate();
                "
              />
            </div>
            <div v-if="this.user.data.type != 'reseller'">
              <input
                v-if="has_static_ip"
                v-model="account.ip_address"
                @input="validate"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :class="{ 'shadow-error': errors.ip_address }"
              />
              <div
                v-if="has_static_ip"
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': errors.ip_address }"
              >
                *{{ errors.ip_address }}
              </div>
            </div>

            <!-- <div>
              <toggle-button
                class="shadow-lg"
                :value="account.record_sessions"
                :enabled_name="$t('accounts.record_sessions')"
                :disabled_name="$t('accounts.record_sessions_false')"
                @toggle="account.record_sessions = !account.record_sessions"
              />
            </div>
            <div>
              <div
                v-if="!account.record_sessions"
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': !account.record_sessions }"
              >
                *{{ $t("errors.accounts.sessions_warning") }}
              </div>
            </div> -->
            <div v-if="hasPrivilege('accounts_auto_renew_accounts')">
              <toggle-button
                class="shadow-lg"
                :value="account.auto_renew"
                :enabled_name="$t('accounts.auto_renew')"
                :disabled_name="$t('accounts.auto_renew_false')"
                @toggle="account.auto_renew = !account.auto_renew"
              />
            </div>
            <div v-if="this.user.data.type != 'reseller'"></div>
            <div
              v-if="this.user.data.type != 'reseller' && account.paid != null"
            >
              <toggle-button
                class="shadow-lg"
                :value="account.paid"
                :enabled_name="$t('accounts.paid')"
                :disabled_name="$t('accounts.paid_false')"
                @toggle="account.paid = !account.paid"
              />
            </div>
            <div
              v-if="this.user.data.type != 'reseller' && account.paid != null"
              class="text-xl bg-white rounded-full px-2"
            >
              {{
                account.profile_id != "" && account.paid
                  ? profiles.filter(
                      (profile) => profile.id === account.profile_id
                    )[0].price
                  : 0
              }}
              {{ this.settings.currency }}
            </div>
            <div>
              <toggle-button
                class="shadow-lg"
                :value="account.is_mac"
                :enabled_name="$t('accounts.is_mac')"
                :disabled_name="$t('accounts.is_mac')"
                @toggle="
                  account.is_mac = !account.is_mac;
                  validate();
                "
              />
            </div>
            <div>
              <input
                v-if="account.is_mac"
                @input="validate"
                v-model="account.mac_address"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :class="{ 'shadow-error': errors.mac_address }"
                placeholder="00:00:00:00:00"
              />
              <div v-if="account.is_mac" class="text-sm">
                {{ $t("messages.accounts.auto_mac") }}
              </div>
            </div>
            <div
              v-if="user.data.type != 'reseller'"
              :class="{ disabled: account.is_mac }"
            >
              <label class="font-bold">{{ $t("accounts.sessions") }}</label>
              <numerical-spinner
                :value="account.allowed_sessions"
                @increase="
                  account.allowed_sessions = account.allowed_sessions + 1
                "
                @decrease="
                  account.allowed_sessions =
                    account.allowed_sessions > 1
                      ? account.allowed_sessions - 1
                      : (account.allowed_sessions = 1)
                "
              />
            </div>
            <div></div>
            <div
              v-if="
                user.data.type != 'reseller' &&
                  account.wait_days != null &&
                  hasPrivilege('accounts_renew_48')
              "
            >
              <label class="font-bold block">{{
                $t("accounts.wait_days")
              }}</label>
              <numerical-spinner
                :value="account.wait_days"
                @increase="account.wait_days = account.wait_days + 1"
                @decrease="
                  account.wait_days =
                    account.wait_days > 0
                      ? account.wait_days - 1
                      : (account.wait_days = 0)
                "
              />
            </div>
            <div
              v-if="this.user.data.type == 'reseller'"
              class="col-span-2 flex flex-col font-bold text-md"
            >
              <div>{{ $t("control.profile_cost", { price: price }) }}</div>
              <div>
                {{
                  $t("control.benefit_cost", {
                    price: (price * settings.benefit_rate) / 100,
                  })
                }}
              </div>
              <div>
                {{
                  $t("control.cost", {
                    cost:
                      price -
                      (price * settings.benefit_rate) / 100 +
                      " " +
                      settings.currency,
                  })
                }}
              </div>
              <div
                :class="{
                  'text-red-600':
                    user.data.money -
                      price +
                      (price * settings.benefit_rate) / 100 <
                    0,
                }"
              >
                {{
                  $t("control.money_left", {
                    original: user.data.money,
                    left:
                      user.data.money -
                      price +
                      (price * settings.benefit_rate) / 100 +
                      " " +
                      settings.currency,
                  })
                }}
              </div>
            </div>
            <div class="col-span-2 flex justify-center">
              <button
                :disabled="!validated"
                @click.prevent="store"
                class="w-full bg-green-500 text-white rounded-full py-1 px-3"
                :class="{
                  ' cursor-not-allowed opacity-50': !validated,
                }"
              >
                {{ this.account.id ? $t("accounts.save") : $t("accounts.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import Page1 from "../components/accountAdditionPages/Page1.vue";
import Page2 from "../components/accountAdditionPages/Page2.vue";
import Page3 from "../components/accountAdditionPages/Page3.vue";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import ErrorMessage from "../components/modals/ErrorMessage.vue";
import Datepicker from "vue3-date-time-picker";
import moment from "moment";
import ExpiredWindow from "../components/ExpiredWindow.vue";
import VueDatepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { hasPrivilege } from "../global_constants.js";
export default {
  components: {
    Page1,
    Page2,
    Page3,
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    ErrorMessage,
    Datepicker,
    ExpiredWindow,
    VueDatepicker,
  },
  name: "AddAccount",
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      error: "",
      message: "",
      has_static_ip: false,
      has_expire_profile: false,
      has_fixed_expiration: false,
      account: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        national_id: "",
        username: "",
        password: "",
        address: "",
        notes: "",
        profile_id: "",
        expire_profile: "",
        ip_address: "",
        allowed_sessions: 1,
        auto_renew: false,
        record_sessions: true,
        fixed_expiration: null,
        paid: true,
        is_mac: false,
        mac_address: "",
        next_profile_id: "",
        serial_number: null,
        wait_days: null,
        reseller_id: null,
      },
      startTime: {
        hours: 0,
        minutes: 0,
      },
      loading: false,
      profiles: null,
      isError: false,
      error_message: "",
      resellers: null,
      errors: {
        username: null,
        password: null,
        profile_id: null,
        ip_address: null,
        serial_number: null,
      },
      validated: false,
      date_format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day}-${month}-${year} ${hour}:${minute}`;
      },
      price: 0,
    };
  },
  async created() {
    this.loading = true;
    await this.fetchProfiles();
    if (this.$route.params.id) {
      await this.fetchAccount(this.$route.params.id);
      this.validated = true;
      if (this.account.serial_number == null) await this.fetchSerial();
    }
    this.fetchResellers();
    this.loading = false;
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async store() {
      this.account.fixed_expiration = moment(
        this.account.fixed_expiration
      ).format("yyyy-MM-DD HH:mm");
      if (this.account.id) {
        if (this.account.profile_id == null) {
          this.validate();
          return;
        }
        const response = await axios.put(
          `/accounts/${this.account.id}`,
          this.account
        );
        if (response.status == 200) {
          if (this.user.data.type == "reseller") {
            this.user.data.money =
              this.user.data.money -
              this.price +
              (this.price * this.settings.benefit_rate) / 100;
          }
          this.$router.push({
            path: "/accounts",
            success: { success: "suceess" },
          });
        }
      } else {
        const response = await axios.post("/accounts", this.account);
        if (response.status == 200) {
          if (this.user.data.type == "reseller") {
            this.user.data.money =
              this.user.data.money -
              this.price +
              (this.price * this.settings.benefit_rate) / 100;
          }
          this.$router.push({
            path: "/accounts",
            query: { success: "success" },
          });
        } else {
          if (response.data.error == "username_already_exists") {
            this.errors.username = this.$t("errors.accounts.username_taken");
            this.validated = false;
          }
          if (response.data.error == "username_already_exists_card") {
            this.errors.username = this.$t("errors.accounts.username_taken");
            this.validated = false;
          }
          if (response.data.error == "serial_number_already_exists") {
            this.errors.serial_number = this.$t(
              "errors.accounts.serial_number_taken"
            );
            this.validated = false;
          }
        }
      }
    },
    async fetchProfiles() {
      const response = await axios.get(
        "/profiles?itr=1&type=subscribtion&rows=1000000"
      );
      if (response.status == 200) this.profiles = response.data.data;
    },
    async fetchResellers() {
      const response = await axios.get(
        "admins?filter=1&type=resellers&rows=10000&itr=true"
      );
      if (response.status == 200) this.resellers = response.data.data;
    },
    async fetchSerial() {
      let profile = this.profiles.filter((profile) => {
        return profile.id == this.account.profile_id;
      });
      profile = profile[0];
      profile = ("000" + profile.id).substr(-3);
      var user = ("000" + this.user.data.id).substr(-3);
      const response = await axios.get(
        `/accounts/serial?starter=${user}${profile}`
      );
      if (response.status == 200)
        this.account.serial_number = response.data.data;
    },
    update_price() {
      let profile = this.profiles.filter((profile) => {
        return profile.id == this.account.profile_id;
      });
      profile = profile[0];
      this.price = profile.price;
    },
    fix_date() {
      if (this.account.id) return;
      let profile = this.profiles.filter((profile) => {
        return profile.id == this.account.profile_id;
      });
      profile = profile[0];
      this.account.fixed_expiration = moment().add(
        profile.expiration_value,
        profile.expiration_unit
      );
      if (profile.expiration_unit != "hours") {
        this.account.fixed_expiration.hours(
          this.settings.default_expiration_hour ?? 12
        );
        this.account.fixed_expiration.minutes(0);
      }
    },
    async fetchAccount(id) {
      const response = await axios.get(`/accounts/${id}`);
      if (response.status == 200) {
        this.account = response.data.data;
        this.account.profile_id = this.account.profile.id;
        if (this.account.expire_profile) {
          // this.account.expire_profile_id = this.account.expire_profile.id;
          this.has_expire_profile = true;
        }
        if (this.account.ip_address) {
          this.has_static_ip = true;
        }
        if (this.account.expires_at) {
          this.account.fixed_expiration = moment(this.account.expires_at);
        }
      }
    },
    changeProfile(profile_id) {
      this.account.profile_id = profile_id;
    },
    validate() {
      this.validated = true;
      this.errors.username = null;
      this.errors.mac_address = null;
      if (!this.account.username) {
        this.errors.username = this.$t("errors.accounts.username_empty");
        this.validated = false;
      }
      if (this.account.username.length < 3) {
        this.validated = false;
        this.errors.username = this.$t("errors.accounts.username_2");
      }
      if (this.account.username.includes("*")) {
        this.validated = false;
        this.errors.username = this.$t("errors.accounts.username_symbol");
      }
      if (
        this.account.username.indexOf("@") !=
        this.account.username.lastIndexOf("@")
      ) {
        this.validated = false;
        this.errors.username = "cannot contain more than @ symbol";
      }
      this.errors.password = null;
      if (!this.account.password) {
        this.validated = false;
        this.errors.password = this.$t("errors.accounts.password_empty");
      }
      if (this.account.password.length < 3) {
        this.validated = false;
        this.errors.password = this.$t("errors.accounts.password_2");
      }
      this.errors.profile_id = null;
      if (!this.account.profile_id) {
        this.validated = false;
        this.errors.profile_id = this.$t("errors.accounts.select_profile");
      }
      this.errors.ip_address = null;
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          this.account.ip_address
        ) == false &&
        this.has_static_ip
      ) {
        this.validated = false;
        this.errors.ip_address = this.$t("errors.accounts.invalid_ip");
      }
      if (
        this.user.data.money -
          this.price +
          (this.price * this.settings.benefit_rate) / 100 <
          0 &&
        this.user.data.type == "reseller"
      ) {
        this.validated = false;
      }
      if (
        /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/.test(
          this.account.mac_address
        ) == false &&
        this.account.is_mac == 1 &&
        this.account.mac_address != ""
      ) {
        this.errors.mac_address = this.$t(
          "errors.accounts.invalid_mac_address"
        );
        this.validated = false;
      }
      if (this.account.is_mac) {
        this.account.allowed_sessions = 1;
      }
    },
  },
};
</script>
